import React, { useRef } from 'react';
import styled from 'styled-components';
import back from '../../Images/Cards/back.png'

const FlipCard = styled.div`
    width: 268px;
    height: 477px;
    perspective: 1000px;
    justify-self: center;
    align-self: center;
    transition: all 0.5s ease 0.05s;
    &:hover{
      transform: scale(1.02) translateY(-0.45rem);
    }
   .flip-card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      transition: transform 0.8s;
      transform-style: preserve-3d;
   }
   .flip-card-inner.animation{
      position:absolute;
      animation: fadeIn 2s ease-in-out;
      @keyframes fadeIn {
        0% {opacity:0; top:-50%}
        100% {opacity:1;top:0%}
      }
    }
   .flip-card-inner.active{
      transform: rotateY(180deg);
   }
  img{
    width: 100%;
    height:100%;
    cursor: pointer; 
  }

  @media (max-width:426px){
    width:257px;
    height:456px;
  }
  @media (min-width: 769px) and (max-width: 900px){
    width:100%;
    height:330px;
  }
  @media (min-width: 900px) and (max-width: 1200px){
    width:220px;
    height:380px;
  }
  @media(min-width:2000px){
    width:80%;
    height:80%;
  }
`
const CardBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;

`
const CardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  transform: rotateY(180deg);
`

const SingleCard = ({ skill, handleFlip, active, selected }) => {
  const cardRef = useRef()
  return (
    <FlipCard ref={cardRef} id={skill.id}>
      <div onClick={() => handleFlip(skill.id, cardRef)} className={active.includes(skill.id) ? "flip-card-inner animation active" : "flip-card-inner animation"}>
        <CardBack>
          <img src={back} alt="Card" />
        </CardBack>
        <CardFront>
          <img src={require(`../../Images/Cards/${selected}/${skill.skill}.gif`).default} alt="Card" />
        </CardFront>
      </div>
    </FlipCard>
  );
}

export default SingleCard;