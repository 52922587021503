import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import zenScroll from "zenscroll";
import CardsData from "../../Data/CardsData";
import ContentData from "../../Data/ContentData";
import Logo from "../../Icons/Logo";
import { Text26 } from "../../Styles/FontStyle";
import SingleCard from "./SingleCard";
import { TextWrapper } from "../Landing/Landing";

const CardsWrapper = styled.div`
  width: 100%;
  height: 100vh;
  @media (max-width: 768px) { 
    padding-top: 30px;
  }
`;
const Box = styled.div`
  height: inherit;
  display: grid;
  grid-template-rows: 3fr 1fr;
  width: 70%;
  margin: 0 auto;
  .content {
    align-self: center;
  }
  .content .description {
    font-size: 24px;
    text-align: center;
  }
  @media (max-width: 768px) {
    grid-template-rows: 1fr 1fr;
    grid-gap: 40px;
    width: 90%;
    .content {
      text-align: center;
    }
    .content .description {
    font-size: 18px;
  }
  }
`;
const CardBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;
  padding-top: 115px;
  @media (max-width: 768px) {
    order: 2;
    grid-template-columns: 1fr;
    gap: 70px;
    padding-top: 0px;
    padding-bottom: 30px;
  }
  @media (min-width: 769px) and (max-width: 1200px) {
    grid-gap: 40px;
  }
`;

const Cards = () => {
  const [selected, setSelected] = useState({});
  const [active, setActive] = useState([]);
  const history = useHistory();
  const [displayArray, setDisplayArray] = useState([]);
  const [displayEl, setDisplayEl] = useState();

  const handleFlip = (id, cardRef) => {
    // on click on card ,scroll to next card on mob/tab
    if (id !== 2) {
      (async function () {
        await delay(1000);
        const yThreshold = window.innerWidth < 769 ? 400 : 0;
        zenScroll.toY(cardRef.current.offsetTop + yThreshold);
      })();
    }
    // populate the list with clicked cards
    if (active.includes(id)) {
      setActive(active);
    } else {
      setActive([...active, id]);
    }
  };
  useEffect(() => {
    // Randomly picking the card from a list of cards
    let number = Math.floor(Math.random() * 10) + 1;
    let proffessionSelected = CardsData.find(
      (element) => number === element.id
    );
    setSelected(proffessionSelected);
  }, []);

  useEffect(() => {
    // if all the cards are clicked,then after timeout redirect to result page
    (async function () {
      if (active.length > 2) {
        await delay(2000);
        history.push({
          pathname: `/result/${selected.key}`,
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);
  // Delay showing on card
  const delay = (ms) =>
    new Promise((res) => {
      setTimeout(() => {
        res();
      }, ms);
    });

  useEffect(() => {
    // when the card page is load, show cards one by one with delay
    if (selected.skills) {
      (async function () {
        for (let el of selected.skills) {
          await delay(500);
          setDisplayEl(el);
        }
        setDisplayEl(undefined);
      })();
    }
  }, [selected]);

  useEffect(() => {
    displayEl && setDisplayArray((prev) => [...prev, displayEl]);
  }, [displayEl]);

  return (
    <CardsWrapper>
      <TextWrapper
        dangerouslySetInnerHTML={{ __html: ContentData.Landing.projectMadeBy }}
      />
      <Box>
        <CardBox>
          {displayArray.map((skill, index) => (
            <SingleCard
              key={index}
              skill={skill}
              handleFlip={handleFlip}
              active={active}
              selected={selected.id}
            />
          ))}
        </CardBox>
        <div className="content">
          <Logo />
          {/* <Text26 className="cardPara">{ContentData.Cards.text}</Text26> */}
          <p className="description">{ContentData.Cards.text}</p>
        </div>
      </Box>
    </CardsWrapper>
  );
};

export default Cards;
