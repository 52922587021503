import React from "react";
import styled from "styled-components";
import Content from "./Content";
import ContentData from "../../Data/ContentData";

const LandingWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
`;
export const TextWrapper = styled.div`
  position: absolute;
  top: 65px;
  right: 5%;
  font-size: 17px;
  font-weight: 600;
  text-align: right;
  width: 350px;
  @media (max-width: 768px) {
    top: 38px;
    font-weight: normal;
    font-size: 10px;
    right: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
  }
`;
const Landing = () => {
  return (
    <LandingWrapper>
      <TextWrapper>
        <p dangerouslySetInnerHTML={{ __html: ContentData.Landing.projectMadeBy}}></p>
      </TextWrapper>
      <Content />
    </LandingWrapper>
  );
};

export default Landing;
