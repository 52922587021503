import { createGlobalStyle } from 'styled-components';
import darkBg from '../Images/Background/darkBg.png'
import lightBg from '../Images/Background/lightBg.png'
import "../css/Inter.css"

export const GlobalStyles = createGlobalStyle`
  body {
    margin:0px;
    padding:0px;
    width:100%;
    height:100vh;
    background: url(${({ theme }) => theme.body});
    color: ${({ theme }) => theme.mainTextColor};
    font-family: 'Raleway', sans-serif;
    position:relative;
    transition: background .5s linear;
    -webkit-transition: background .5s linear;
    -moz-transition: all .5s linear;
    -o-transition: all .5s linear;
    box-sizing:border-box;
  }
  .logo{
    fill:${({ theme }) => theme.logo};
  }
  .color{
    color:${({ theme }) => theme.color};
    font-weight:bold;
  }
  .border-bottom {
    border-bottom: ${({ theme }) => theme.borderBottom};
  }
  .btnColor{
    background:${({ theme }) => theme.color};
    border:none;
  }
  .circle{
    stroke:${({ theme }) => theme.circle};
  }
  .cardPara{
    color: ${({ theme }) => theme.mainTextColor};
  }
  .transparent{
    background: #1c1a25;
    border-radius: 25px;
    padding:25px;
    margin-bottom:20px;
  }
  .shareIcon{
    fill:${({ theme }) => theme.logo};
  }
  .shareIconMobile ,.reset{
    fill:${({ theme }) => theme.resultIconMobile};
  }
  .linearGradient{
    background: ${({ theme }) => theme.linearGradient};
  }
  .seeMore{
    fill:${({ theme }) => theme.color};
  }
  .border-bottom {

  }
`;

export const lightTheme = {
  body: lightBg,
  logo: '#100824',
  mainTextColor: '#302F38',
  color: "#163BC0",
  circle: "#234BDB",
  transparentBox: "rgba(196, 196, 196, 0.3)",
  resultIconMobile: "#000000",
  linearGradient: "linear-gradient(354.91deg, #EDEDED 70.81%, rgba(237, 237, 237, 0) 192.09%);",
  borderBottom: "4px solid #FE4759"
};

export const darkTheme = {
  body: darkBg,
  logo: "#FFFFFF",
  mainTextColor: '#FFFFFF',
  color: "#FE4759",
  circle: "#FE4759",
  transparentBox: "rgba(196, 196, 196, 0.11)",
  resultIconMobile: "#FFFFFF",
  linearGradient: "linear-gradient(356.05deg, #100824 30.33%, rgba(16, 8, 36, 0.94) 65.15%, rgba(16, 8, 36, 0.05) 111.97%)",
  borderBottom: "4px solid #FE4759"
};