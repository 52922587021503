import React from 'react'
import styled from "styled-components";

const HalfEyeIconBox = styled.div`
  @media (max-width: 768px) {
    width: 60%;
    margin-left: auto;
    margin-right: auto;

    img {
      display: block;
      width: 100%;
    }
  }
`;


export default function HalfEye() {
  return (
    <HalfEyeIconBox>
       <img src="/halfEyeIcon.svg" alt="" />
    </HalfEyeIconBox>
  )
}
