import './css/App.css';
import './css/Releway.css';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { darkTheme, GlobalStyles, lightTheme } from './Styles/globalStyles';
import { useDarkMode } from './Hooks/useDarkMode';
import Landing from './Components/Landing/Landing';
import Cards from './Components/Cards/Cards';
import Result from './Components/Results/Result';
import NoFound from './Components/Error/NoFound';
import figlet from 'figlet';
import standard from 'figlet/importable-fonts/Standard.js'
import Award from './Components/Award/Award';

figlet.parseFont('Standard', standard);

const App = () => {
  const [theme, toggleTheme] = useDarkMode();
  const themeMode = theme === 'light' ? lightTheme : darkTheme;

  figlet.text('BRAINSTER.CO', {
    font: 'Standard',
  }, function (err, data) {
    console.log(data);
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <GlobalStyles />
      <Router>
        <Switch>
          <Route exact path="/">
            <Landing theme={theme} toggleTheme={toggleTheme} />
          </Route>
          <Route path="/cards" component={Cards} />
          <Route path="/result/:key" component={Result} />
          <Route path={`/blueprint/:key`} component={Result} />
          <Route path="/award" component={Award} />
          <Route component={NoFound} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;