import React from "react";

export default function BrainsterLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="101"
      height="12"
      viewBox="0 0 101 12"
      fill="none"
    >
      <path
        d="M8.4608 5.94088C8.34196 5.87043 8.34196 5.75301 8.43703 5.68256C9.15002 5.18941 9.60158 4.46143 9.60158 3.40468C9.60158 1.57297 8.3182 0.210938 5.79897 0.210938H0.356494C0.166364 0.210938 0 0.375321 0 0.563188V11.4125C0 11.6004 0.166364 11.7648 0.356494 11.7648H6.32183C8.69846 11.7648 10.1244 10.4262 10.1244 8.50057C10.1244 7.25595 9.48275 6.41055 8.4608 5.94088ZM2.99455 3.19333C2.99455 3.02894 3.13715 2.88804 3.30351 2.88804H5.63261C6.25053 2.88804 6.58326 3.26378 6.58326 3.80389C6.58326 4.34401 6.2743 4.71975 5.46624 4.71975H3.30351C3.13715 4.71975 2.99455 4.57885 2.99455 4.41446V3.19333ZM5.9891 9.08765H3.30351C3.13715 9.08765 2.99455 8.94675 2.99455 8.78237V7.34988C2.99455 7.1855 3.13715 7.0446 3.30351 7.0446H6.01287C6.82092 7.0446 7.12988 7.44382 7.12988 8.0309C7.10612 8.64147 6.79716 9.08765 5.9891 9.08765Z"
        fill="white"
      />
      <path
        d="M20.1297 8.52405C20.0346 8.38315 20.0584 8.21877 20.2247 8.14832C21.6269 7.39685 22.2686 5.96437 22.2686 4.43795C22.2686 2.13657 20.8902 0.210938 17.8956 0.210938H12.5245C12.3343 0.210938 12.168 0.375321 12.168 0.563188V11.4125C12.168 11.6004 12.3343 11.7648 12.5245 11.7648H14.7823C14.9724 11.7648 15.1388 11.6004 15.1388 11.4125V8.97024C15.1388 8.80585 15.2814 8.66495 15.4477 8.66495H16.3746C16.5647 8.66495 16.7549 8.75889 16.8499 8.89979L18.6799 11.4595C18.8225 11.6473 19.0364 11.7648 19.2503 11.7648H22.1023C22.2449 11.7648 22.3399 11.6004 22.2449 11.4595L20.1297 8.52405ZM17.7293 5.98785H15.4715C15.3051 5.98785 15.1625 5.84695 15.1625 5.68256V3.19333C15.1625 3.02894 15.3051 2.88804 15.4715 2.88804H17.7293C18.9176 2.88804 19.3692 3.54558 19.3692 4.43795C19.3692 5.25986 18.9176 5.98785 17.7293 5.98785Z"
        fill="white"
      />
      <path
        d="M31.1343 0.539705C31.063 0.351838 30.8491 0.210938 30.6352 0.210938H28.3774C28.1635 0.210938 27.9496 0.351838 27.8783 0.539705L23.4103 11.436C23.339 11.5769 23.4578 11.7648 23.6242 11.7648H26.1672C26.3811 11.7648 26.5712 11.6239 26.6425 11.436L27.0703 10.3558C27.1416 10.1914 27.2842 10.074 27.4743 10.074H31.4908C31.6809 10.074 31.8473 10.1914 31.8948 10.3558L32.3226 11.436C32.3939 11.6239 32.5841 11.7648 32.798 11.7648H35.3409C35.5311 11.7648 35.6261 11.5769 35.5548 11.436L31.1343 0.539705ZM30.4689 7.39685H28.4963C28.3537 7.39685 28.2586 7.25595 28.3061 7.11505L29.3756 4.29705C29.3994 4.20311 29.5657 4.20311 29.5895 4.29705L30.659 7.11505C30.7303 7.23247 30.6352 7.39685 30.4689 7.39685Z"
        fill="white"
      />
      <path
        d="M39.9984 0.210938H37.7168C37.5267 0.210938 37.3604 0.375321 37.3604 0.563188V11.4125C37.3604 11.6004 37.5267 11.7648 37.7168 11.7648H39.9984C40.1885 11.7648 40.3549 11.6004 40.3549 11.4125V0.563188C40.3549 0.375321 40.1885 0.210938 39.9984 0.210938Z"
        fill="white"
      />
      <path
        d="M53.284 0.210938H51.0262C50.836 0.210938 50.6697 0.375321 50.6697 0.563188V6.36358C50.6697 6.45752 50.5508 6.50448 50.4795 6.43403L45.6788 0.492737C45.5362 0.30487 45.3223 0.210938 45.0846 0.210938H43.2784C43.0882 0.210938 42.9219 0.375321 42.9219 0.563188V11.4125C42.9219 11.6004 43.0882 11.7648 43.2784 11.7648H45.5362C45.7263 11.7648 45.8927 11.6004 45.8927 11.4125V5.61211C45.8927 5.51818 46.0115 5.47121 46.0828 5.54166L50.9073 11.4595C51.0499 11.6473 51.2638 11.7413 51.5015 11.7413H53.3077C53.4979 11.7413 53.6642 11.5769 53.6642 11.389V0.563188C53.6405 0.375321 53.4741 0.210938 53.284 0.210938Z"
        fill="white"
      />
      <path
        d="M60.9604 4.5088C59.5582 4.03914 59.0353 3.8043 59.0353 3.33464C59.0353 2.9589 59.4394 2.55969 60.4613 2.55969C61.4595 2.55969 62.0299 3.00587 62.1963 3.49902C62.2913 3.75734 62.4102 3.89824 62.6953 3.89824H64.7868C65.0007 3.89824 65.1908 3.68689 65.167 3.47554C65.0245 1.409 63.1231 0 60.39 0C57.7282 0 55.8269 1.38552 55.8269 3.59295C55.8269 5.98826 58.2748 6.85714 60.1286 7.46771C61.5308 7.93738 62.125 8.24266 62.125 8.73581C62.125 9.08806 61.7685 9.44031 60.6514 9.44031C59.6057 9.44031 58.9878 9.08806 58.7739 8.68884C58.5838 8.38356 58.5125 8.31311 58.1798 8.31311H56.1596C55.9457 8.31311 55.7556 8.52446 55.7794 8.73581C55.9933 10.8493 58.0847 12 60.7227 12C63.3846 12 65.3809 10.8258 65.3809 8.57143C65.3334 6.01174 62.5765 5.07241 60.9604 4.5088Z"
        fill="white"
      />
      <path
        d="M76.6224 0.210938H67.0684C66.8783 0.210938 66.7119 0.375321 66.7119 0.563188V2.72366C66.7119 2.91153 66.8783 3.07591 67.0684 3.07591H70.063C70.2293 3.07591 70.3719 3.21681 70.3719 3.38119V11.4125C70.3719 11.6004 70.5383 11.7648 70.7284 11.7648H73.01C73.2001 11.7648 73.3665 11.6004 73.3665 11.4125V3.38119C73.3665 3.21681 73.5091 3.07591 73.6754 3.07591H76.67C76.8601 3.07591 77.0265 2.91153 77.0265 2.72366V0.563188C77.0027 0.375321 76.8363 0.210938 76.6224 0.210938Z"
        fill="white"
      />
      <path
        d="M87.4362 0.210938H79.1656C78.9754 0.210938 78.8091 0.375321 78.8091 0.563188V11.4125C78.8091 11.6004 78.9754 11.7648 79.1656 11.7648H87.4362C87.6264 11.7648 87.7927 11.6004 87.7927 11.4125V9.27552C87.7927 9.08765 87.6264 8.92327 87.4362 8.92327H82.1126C81.9462 8.92327 81.8036 8.78237 81.8036 8.61799V7.72562C81.8036 7.56123 81.9462 7.42033 82.1126 7.42033H86.3668C86.5569 7.42033 86.7232 7.25595 86.7232 7.06808V5.09548C86.7232 4.90761 86.5569 4.74323 86.3668 4.74323H82.1126C81.9462 4.74323 81.8036 4.60233 81.8036 4.43795V3.38119C81.8036 3.21681 81.9462 3.07591 82.1126 3.07591H87.4362C87.6264 3.07591 87.7927 2.91153 87.7927 2.72366V0.563188C87.7927 0.375321 87.6264 0.210938 87.4362 0.210938Z"
        fill="white"
      />
      <path
        d="M100.104 11.4595L97.9648 8.5006C97.8697 8.3597 97.8935 8.19532 98.0599 8.12487C99.4621 7.3734 100.104 5.94092 100.104 4.4145C100.104 2.11313 98.7253 0.1875 95.7308 0.1875H90.3833C90.1932 0.1875 90.0269 0.351883 90.0269 0.53975V11.3891C90.0269 11.5769 90.1932 11.7413 90.3833 11.7413H92.6411C92.8313 11.7413 92.9976 11.5769 92.9976 11.3891V8.94679C92.9976 8.78241 93.1402 8.64151 93.3066 8.64151H94.2335C94.4236 8.64151 94.6138 8.73544 94.7088 8.87634L96.5388 11.436C96.6814 11.6239 96.8953 11.7413 97.1092 11.7413H99.9612C100.104 11.7648 100.199 11.6004 100.104 11.4595ZM95.5644 5.98789H93.2828C93.1165 5.98789 92.9739 5.84699 92.9739 5.6826V3.19337C92.9739 3.02898 93.1165 2.88809 93.2828 2.88809H95.5406C96.729 2.88809 97.1805 3.54562 97.1805 4.43799C97.2043 5.2599 96.7527 5.98789 95.5644 5.98789Z"
        fill="white"
      />
    </svg>
  );
}
