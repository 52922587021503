import styled from 'styled-components';


export const Headline60 = styled.h1`
    padding-top:50px;
    font-size: 60px;
    font-weight: 800;
    line-height: normal;
    white-space:pre-line;
    margin:0px;
    box-sizing:border-box;
    @media (max-width: 426px) {
        font-size: 22px;
        line-height: 26px;
        padding-top:50px;
    }
    @media (min-width: 426px) and (max-width: 768px) {
        font-size: 22px;
        line-height: 36px;
        padding-top:50px;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 30px;
        line-height: 34px;
    }
    @media (min-width:2000px){
       font-size: 50px;
    }
`;



// Text
export const Text26 = styled.p`
    text-align:center;
    white-space:pre-line;
    font-weight: normal;
    font-size: 26px;
    margin:0px;
    @media (max-width: 768px) {
        font-size:22px;
        padding-top:26px;
    }
    @media (min-width:1600px){
        font-size: 32px;
    }
    @media (min-width:2000px){
        font-size: 42px;
    }
`;

export const Text18 = styled.p`
    font-weight: normal;
    font-size: 18px;
    @media (min-width:1600px){
        font-size: 24px;
    }
    @media (min-width:2500px){
        font-size: 34px;
    }
`;
export const Text20 = styled.p`
    font-weight: normal;
    font-size: 20px;
    @media (min-width:1600px){
        font-size: 24px;
    }
    @media (min-width:2500px){
        font-size: 34px;
    }
`;
