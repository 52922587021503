import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ContentData from "../../Data/ContentData";
import { useLocation } from "react-router-dom";
import Logo from "../../Icons/Logo";
import { SeeMore } from "../../Icons/Icons";
import usePopUp from "../../Hooks/usePopUp";
import { Text18, Text20, Text26 } from "../../Styles/FontStyle";
import CardsData from "../../Data/CardsData";
import { TextWrapper } from "../Landing/Landing";
import BrainsterLogo from "../../Icons/BrainsterLogo";
import BrainsterNextLogo from "../../Icons/BrainsterNextLogo";

const ResultWrapper = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 80%;
  margin: 0 auto;
  grid-gap: 100px;
  padding-top: 115px;
  box-sizing: border-box;
  animation: fadeIn 2s ease-in-out;
  @keyframes fadeIn {
    0% {
      opacity: 0;
      top: -50%;
    }
    100% {
      opacity: 1;
      top: 0%;
    }
  }
  @media (max-width: 768px) {
    width: 90%;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    padding-top: 0px;
    &.readLess {
      position: fixed;
      left: 0;
      right: 0;
    }
    &.readMore {
      position: initial;
    }
    .link.desktop {
      display: none;
    }
  }
`;
const Left = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .link {
    display: flex;
    justify-content: center;
  }
  .btnReset {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    white-space: nowrap;
    margin-top: 30px;
    font-weight: bold;
    text-decoration: none;
    svg {
      margin-right: 9px;
    }
  }
  @media (max-width: 768px) {
    .logoWrapper {
      margin-top: 30px;
    }
  }
  @media (min-width: 1600px) {
    .btnReset {
      font-size: 22px;
    }
  }
`;
const Right = styled.div`
  align-self: center;

  .transparent span {
    font-size: 18px;
    margin-right: 7px;
  }
  .grid {
    display: grid;
    grid-template-columns: 2fr 0.5fr;
    grid-gap: 10px;
  }
  .bold {
    text-transform: uppercase;
    margin: 0px;
    font-weight: bold;
    text-align: left;
  }
  .para {
    font-weight: bold;
    text-transform: uppercase;
    white-space: pre-line;
    margin: 0px;
  }
  .text-under {
    font-size: 22px;
    font-weight: 500;
    width: 85%;
  }
  .brainster-logos {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 25px;
  }
  .brainster-logos .divider {
    width: 1px;
    height: 12px;
    background-color: white;
  }
  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }
  @media (max-width: 1400px) {
    .grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
  }

  @media (max-width: 768px) {
    text-align: center;
    padding: 20px 0px;
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
    .brainster-logos {
      justify-content: center;
    }
    .text-under {
      font-size: 18px;
      font-weight: normal;
      width: 100%;
    }
    /* .transparent {
      background: none;
      padding: 0px;
    } */
    .para {
      width: 90%;
      margin: 0 auto;
    }
    .bold {
      text-align: center;
    }
  }
`;
const ButtonLink = styled.a`
  border-radius: 40px;
  cursor: pointer;
  color: #ffffff;
  font-weight: bold;
  align-self: center;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  text-decoration: none;
  text-align: center;
  padding: 15px 0;
  width: 215px;
  transition: all 0.5s ease-in-out;
  

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    padding: 17px 37px;
    justify-self: center;
  }
  @media (min-width: 769px) and (max-width: 1400px) {
    justify-self: flex-start;
  }
`;
const Image = styled.div`
  height: 488.39px;
  img {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 769px) {
    width: 234px;
    height: 424.84px;
    padding-top: 36px;
  }
  @media (min-width: 769px) and (max-width: 1200px) {
    height: 400px;
    padding-top: 36px;
  }
`;
const Mobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 30px;
  }
`;
const FadeIn = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 40px 0px 20px;
  p {
    font-size: 18px;
  }
`;


const Result = (props) => {
  const location = useLocation();
  const [popUp, handlePopUp] = usePopUp();
  const [result, setResult] = useState();

  useEffect(() => {
    if (props.match.params) {
      let filtered = CardsData.find(
        (element) => element.key === props.match.params.key
      );
      setResult(filtered);
    }
  }, [props.match.params]);

  useEffect(() => {
    // location.state contains a profession that is selected (selected profession is sent in card component)
    location.state && setResult(location.state.selected);
  }, [location]);

  return (
    <>
      <TextWrapper
        dangerouslySetInnerHTML={{ __html: ContentData.Landing.projectMadeBy }}
      />
      {result && (
        <ResultWrapper className={popUp ? "readMore" : "readLess"}>
          <Mobile className="mobileColor">
            {popUp ? null : (
              <FadeIn className="linearGradient">
                <p className="color">{ContentData.Result.readMore}</p>
                <SeeMore handlePopUp={handlePopUp} />
              </FadeIn>
            )}
          </Mobile>
          <Left>
            <Logo />
            <Image>
              <img
                src={
                  require(`../../Images/Cards/${result.id}/${result.resultGif}`)
                    .default
                }
                alt="Result"
              />
            </Image>
          </Left>
          <Right>
            <div className="transparent">
              <Text26 className="bold">{result.heading}</Text26>
              <Text18>{result.text}</Text18>
              {result.tags.map((tag, index) => (
                <span className="color" key={index}>
                  {tag}
                </span>
              ))}
            </div>
            <div className="transparent grid">
              <div>
                <Text20 className="para">{ContentData.Result.text}</Text20>

                <p
                  className="text-under"
                  dangerouslySetInnerHTML={{
                    __html: ContentData.Result.textunder,
                  }}
                ></p>

                {/* <p
                  className="text-under mobile"
                  dangerouslySetInnerHTML={{
                    __html: ContentData.Result.textunderMobile,
                  }}
                ></p> */}
              </div>
              <ButtonLink
                href={process.env.REACT_APP_TYPEFORM_LINK}
                className="btnColor"
              >
                {ContentData.Result.link}
              </ButtonLink>
            </div>
            <div className="brainster-logos">
              <a href="https://brainster.co/" target="_blank">
                <BrainsterLogo />
              </a>

              <div className="divider"></div>
              <a href="https://next.edu.mk/" target="_blank">
                <BrainsterNextLogo />
              </a>
            </div>
          </Right>
        </ResultWrapper>
      )}
    </>
  );
};

export default Result;
