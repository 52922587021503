import React from "react";
import styled from "styled-components";

const EyeIconBox = styled.div`
  @media (max-width: 768px) {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;

    img {
      display: block;
      width: 100%;
    }
  }

`;

export default function Eye() {
  return (
    <EyeIconBox>
      <img src="/eyeIcon.svg" alt="" />
    </EyeIconBox>
  );
}
