let ContentData = {
    Landing: {
        mainText: "Драг Brain'o'Bot, tech предвидувач на\n трендови и идни кариери, откриј ми ја \n иднината и додели ми награда!",
        mainTextMobile: "Драг Brain'o'Bot, tech предвидувач <br />  на трендови и идни кариери,\n откриј ми ја иднината\n  и додели ми награда!",
        button: "Sирни во иднината",
        projectMadeBy: "Oвој проект е целосно изработен од студентите на Brainster"
    },
    Cards: {
        text: "Размисли длабоко за иднината и кликни на картите."
    },
    Result: {
        reset: "Врти Повторно",
        text: "Не дозволувај игра да\n одлучи за твојата иднина :)",
        textunder: "Земи ги картите што ти се поделени во свои раце и учи ги најбараните tech професеии во Brainster и Brainster Next.",
        textunderMobile: "Земи ги картите што ти се поделени во свои раце и учи ги најбараните tech професеии во  Brainster и Brainster Next.",
        link: "Сакам награда",
        share: "Сподели",
        shareIcons: ["fb", "instagram", "twitter", "linkedin"],
        readMore: "Види повеќе"
    },
    Award: {
        heading:" Освои Brainster награда!",
        subHeading: "Начини на подигање на наградата:",
        options: [
            {
                title: "Опција 1",
                content: "Најди некој од нашиот тим со Brainster маичка на фестивалот и потврди ги твоите податоци."
            },
            {
                title: "Опција 2",
                content: "Доколку си во гужва секогаш си  добредојден/а во нашите простории во Brainster, ул.Васил Ѓоргов 19, Капиштец."
            },
        ],
        ctaTitle: "Во меѓувреме разгледај ги можностите да се стекнеш со кариера од иднината!",
        socialMedia: "Следни не",
        footer_message: "Уживај на фестивалот!"
    },
    NoFound: {
        number: "404",
        text: "Страницата не е пронајдена",
        button: "ВРАТЕТЕ СЕ НА ПОЧЕТНАТА СТРАНИЦА"
    }
}

export default ContentData