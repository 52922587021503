import React from "react";
import styled from "styled-components";

const CtaBtnBox = styled.button`
  border-radius: 40px;
  background-color: ${(props) => props.bgColor};
  color: white;
  font-weight: 500;
  font-size: 24px;
  width: 324px;
  height: 75px;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: none;
  margin-top: 60px;
  transition: all 0.5s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    height: 55px;
    font-size: 22px;
  }
`;

export default function CtaBtn({ title, bgColor }) {
  return <CtaBtnBox bgColor={bgColor}>{title}</CtaBtnBox>;
}
