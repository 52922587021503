import React from "react";
import styled from "styled-components";
import { FB, Instagram, Linkedin } from "../../Icons/Icons";
import ContentData from "../../Data/ContentData";

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 100px;

  span {
    color: #8b8b8b;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  @media (max-width: 768px) { 
    margin-top: 60px;
    padding-bottom: 30px;

  }
`;

export default function SocialMedia() {
  return (
    <Wrapper>
      <span>{ContentData.Award.socialMedia}</span>
      <a href="https://www.facebook.com/brainster.co/" target="_blank"><FB /></a>
      <a href="https://www.instagram.com/brainsterco/" target="_blank"><Instagram /></a>
      <a href="https://www.linkedin.com/school/brainster-co/" target="_blank"><Linkedin /></a>
    </Wrapper>
  );
}
