import React from "react";
import styled from "styled-components";

import HalfEye from "../../Icons/HalfEye";
import ContentData from "../../Data/ContentData";
import { Text26 } from "../../Styles/FontStyle";
import CtaBtn from "../Landing/CtaBtn";
import { darkTheme } from "../../Styles/globalStyles";
import SocialMedia from "../SocialMedia/SocialMedia";
import Logo from "../../Icons/Logo";

const Wrapper = styled.section`
  width: 90%;
  max-width: 1500px;
  margin: 0 auto;
  height: 100vh;
`;
const IconBox = styled.div`
  display: flex;
  justify-content: center;
`;
const Heading55 = styled.h2`
  color: #fff;
  text-align: center;
  font-size: 55px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  @media (max-width: 768px) {
    font-size: 36px;
    margin-top: 45px;
  }
`;
const ContentBox = styled.div`
  border-radius: 35px;
  background: #1c1a25;
  width: 100%;
  max-width: 1335px;
  margin: 0 auto;
  padding: 20px 0;

  h2 {
    font-size: 26px;
    padding: 0px 40px;
  }

  @media (max-width: 768px) {
    border-radius: 15px;
    width: 100%;
    padding: 15px 0;

    h2 {
      font-size: 18px;
      padding: 15px 10px;
    }
  }
`;

const AwardOptionsBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-family: Inter;

  h3 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.color};
    font-family: Inter;
  }
  p {
    font-size: 22px;
    line-height: 26px;
  }
  article {
    padding: 0px 40px;
  }

  @media (max-width: 768px) {
    display: block;

    article {
      padding: 0px 10px;
    }
    p {
      font-size: 20px;
      line-height: 24px;
    }
  }
`;
const CtaSection = styled.div`
  margin-top: 60px;
  max-width: 1335px;
  margin-left: auto;
  margin-right: auto;

  .link-buttons-box {
    display: flex;
    gap: 24px;
    /* width: 80%; */
  }

  .cta-title {
    font-weight: 500;
    font-family: Inter;
  }
  a {
    display: inline-block;
    width: 100%;
  }
  a button {
    width: 100%;
    font-family: Inter;
    font-size: 18px;
  }
  @media (max-width: 768px) {
    margin-top: 40px;
    width: 100%;

    .link-buttons-box {
      width: 100%;
      display: block;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

    a button {
      margin-top: 16px;
      height: 75px;
      font-size: 16px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`;
const Footer = styled.footer`
margin-top: 80px;

display: flex;
flex-direction: column;
align-items: center;
a {
  margin-top: 0;
  display: inline-flex;
  justify-content: center;
  position: static;
  width: 212px;

  }

  p {
    color: #fff;
    text-align: center;
    font-size: 20px;
    margin-bottom: 5px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  @media (max-width: 768px) { 
    margin-top: 50px;
    padding-bottom: 50px;
    p {font-size: 16px }
    a {
      width: 150px;
    }
  }
`;
export default function Award() {
  return (
    <div>
      <Wrapper>
        <IconBox>
          <HalfEye />
        </IconBox>
        {/* -------- heading ------ */}
        <Heading55>{ContentData.Award.heading}</Heading55>
        {/* -------- award options ----- */}
        <ContentBox>
          <h2>{ContentData.Award.subHeading}</h2>
          <AwardOptionsBox>
            {ContentData.Award.options.map((option, index) => (
              <article key={index}>
                <h3>{option.title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: option.content,
                  }}
                ></p>
              </article>
            ))}
          </AwardOptionsBox>
        </ContentBox>
        {/* -------- cta section ----- */}
        <CtaSection>
          <Text26 className="cta-title">{ContentData.Award.ctaTitle}</Text26>
          <div className="link-buttons-box">
            <a href="https://next.edu.mk/" target="_blank">
              <CtaBtn
                bgColor={darkTheme.color}
                title="Дознај повеќе за Факултетот - BrainsterNext"
                link=""
              />
            </a>
            <a href="https://brainster.co/" target="_blank">
              <CtaBtn
                bgColor="#3C765D"
                title="Дознај повеќе за Академиите - Brainster"
                link=""
              />
            </a>
          </div>
        </CtaSection>
        {/* -------- social media links ----- */}
        <Footer >
          <p>{ContentData.Award.footer_message}</p>
          <Logo />
        </Footer>
        {/* <SocialMedia /> */}
      </Wrapper>
    </div>
  );
}
