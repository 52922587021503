import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ContentData from '../../Data/ContentData';
import { Text26 } from '../../Styles/FontStyle';

const ErrorPageWrapper = styled.div`
   width: 100%;
   height: 100vh;
   display:flex;
   flex-direction: column;
   justify-content:center;
   align-items:center;
   .btnBack{
        font-size18px;
        white-space: nowrap;
        padding:18px;
        margin-top:20px;
        text-decoration:none;
        border-radius: 15px;
        cursor:pointer;
        color: #FFFFFF;
        font-weight: bold;
        transition: all 0.5s ease 0.05s;
        &:hover{
          transform: scale(1.02) translateY(0.45rem);
        }
    }
`
const Number = styled.p`
   font-style: normal;
   font-size: 96px;
   font-weight:bold;
   line-height: 70px;
   padding-bottom:40px;
   margin:0;
`
const NoFound = () => {
    return (
        <ErrorPageWrapper>
            <Number>{ContentData.NoFound.number}</Number>
            <Text26>{ContentData.NoFound.text}</Text26>
            <Link to="/" type="button" className="btnBack btnColor">{ContentData.NoFound.button}</Link>
        </ErrorPageWrapper>
    );
}

export default NoFound;