import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import ContentData from "../../Data/ContentData";
import Logo from "../../Icons/Logo";
import { Headline60 } from "../../Styles/FontStyle";
import Eye from "../../Icons/Eye";
import CtaBtn from "./CtaBtn";
import { darkTheme } from "../../Styles/globalStyles";

const ContentWrapper = styled.div`
  height: 100vh;
  display: grid;

  .wrapper {
    margin-top: 150px;
    align-self: top;
    justify-self: center;
    text-align: center;
  }
  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }
  box-sizing: border-box;
  @media (max-width: 1024px) {
    width: 80%;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    width: 90%;
    position: initial;

    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
    .wrapper {
      margin-top: 100px;
    }
  }
`;

const Content = () => {
  return (
    <ContentWrapper>
      <div className="wrapper">
        <Logo />

        <Eye />
        <div className="desktop">
          <Headline60
            dangerouslySetInnerHTML={{
              __html: ContentData.Landing.mainText
                .replace("Brain'o'Bot", "<b>Brain'o'Bot</b>")
                .replace(
                  "откриј ми ја",
                  "<span class='border-bottom'>откриј ми ја</span>"
                )
                .replace(
                  "иднината",
                  "<span class='border-bottom'>иднината</span>"
                ),
            }}
          />
        </div>
        <div className="mobile">
          <Headline60
            dangerouslySetInnerHTML={{
              __html: ContentData.Landing.mainTextMobile
                .replace("Brain'o'Bot", "<b>Brain'o'Bot</b>")
                .replace(
                  "откриј ми ја",
                  "<span class='border-bottom'>откриј ми ја</span>"
                )
                .replace(
                  "иднината",
                  "<span class='border-bottom'>иднината</span>"
                ),
            }}
          />
        </div>
        <Link to="/cards">
          <CtaBtn
            title={ContentData.Landing.button}
            bgColor={darkTheme.color}
          />
        </Link>
      </div>
      {/* <BtnCircle /> */}
    </ContentWrapper>
  );
};

export default Content;
