let CardsData = [
    {
        id: 1,
        key: "creative-copywriter",
        resultGif: "creative-copywriter.gif",
        heading: "Креативен копирајтер",
        text: "Ќе ѝ нема крај на твојата креативност во иднината! Дигиталните ѕвезди кажуваат дека ќе бидеш креативен копирајтер. Ќе пишуваш на теми за вештачката интелигенција и за виртуелната реалност. Ќе создаваш различни содржини: од значењето на технологијата во универзумот, па сѐ до животот на мачките на Марс. Животот ќе ти биде опкружен со успеси и награди за твоите креативни достигнувања и сите ќе го чекаат твојот следен кул слоган.",
        tags: ["#2cool4u"],
        skills: [{ id: 0, skill: "magicna-kreativnost" }, { id: 1, skill: "prisposoblivost" }, { id: 2, skill: "beskraen-recnik" }]
    },
    {
        id: 2,
        key: "data-analyst",
        resultGif: "data-analyst.gif",
        heading: "Дата Аналитичар",
        text: "Тебе ништо нема да може да те изненади! Ќе поседуваш способност да ја предвидиш иднината на технологиите. Ќе знаеш што ќе се случува со сите ИТ- стартапи во светот! Ќе бидеш во чекор со создавањето на продуктите на иднината: креирање роботи за автоматизација на секојдневни процеси, холограмски часовници, вселенски бродови за превоз на туристи во вселената и виртуелни училници за вонземјани. Твоите аналитички вештини ќе те направат виртуоз кој ќе има способност да ја предвидува иднината на маркетингот.",
        tags: ["#data4thefuture", "#marketing4ever"],
        skills: [{ id: 0, skill: "strateg" }, { id: 1, skill: "roden-analiticar" }, { id: 2, skill: "koristi-podatoci" }]
    },
    {
        id: 3,
        key: "robotics-engineer",
        resultGif: "robotics-engineer.gif",
        heading: "Инженер за роботика",
        text: "Дигиталните ѕвезди ти предвидуваат дека за скоро време ќе станеш инженер за роботика. Ќе им помогнеш на сите да си го добијат своето робот-другарче. Нема веќе сам да ги изработуваш секојдневните обврски - роботчињата ќе ти се наоѓаат во сѐ! Утринското кафе ќе ти го спрема Fable, до работа ќе те носи самоуправувачко летачко возило, а ручекот ќе ти го подготвува Alexa. Со твоите верни роботи ќе ни го олеснуваш животот.",
        tags: ["#robots4life"],
        skills: [{ id: 0, skill: "3d-vizualizacija" }, { id: 1, skill: "AB-testiranje" }, { id: 2, skill: "Avtomatizacija" }]
    },
    {
        id: 4,
        key: "cyber-security",
        resultGif: "cyber-security.gif",
        heading: "Инженер за сајбер безбедност",
        text: "Твојата идна професија ќе биде нешто што можеш да го видиш само во cyber-punk филмовите. Ќе бидеш шеф на tech-обезбедувањето кое ќе се грижи за сајбер безбедноста на планетата Земја и целиот универзум. Во ѕвездите е запишано дека заедно со твојот тим ќе го обезбедувате сајбер просторот за сите вонземјани кои почнуваат да користат компјутери. Како добри сајбер-вили ќе ги учите зошто не треба да продаваат вселенски бродови на dark web и како да хакираат за добротворни цели",
        tags: ["#aliens4websafety"],
        skills: [{ id: 0, skill: "hakiranje" }, { id: 1, skill: "dark-web" }, { id: 2, skill: "forenzicar" }]
    },
    {
        id: 5,
        key: "software-tester",
        resultGif: "software-tester.gif",
        heading: "Софтвер тестер",
        text: "Тест 1-2? Поради тебе, поимот software bug веќе нема да постои. Треба нешто повеќе да ти кажеме?",
        tags: ["#bugkiller"],
        skills: [{ id: 0, skill: "tehniki-na-testiranje" }, { id: 1, skill: "agilna-metodologija" }, { id: 2, skill: "logika-i-analitika" }]
    },
    {
        id: 6,
        key: "ml-engineer",
        resultGif: "ml-engineer.gif",
        heading: "Инженер за машинско учење",
        text: "Предвидено ти е да бидеш инженер за машинско учење! Ќе го смениш концептот на користење на големи оружја преку вештачка интелигенција. Ќе креираш прототип на снајпер што ќе ги лоцира сите површини кои можат да бидат зелени и автоматски ќе садат дрвја. И не само на планетава, моделот ќе обезбедува зелени површини на сите планети во универзумот. Гледаме и дека ќе ја добиеш првата Нобелова награда за мир во новиот екосистем создаден од планетата Земја и на сите другите планети.",
        tags: ["#datascience4peace"],
        skills: [{ id: 0, skill: "vestacka-intelegencija" }, { id: 1, skill: "pametni-algoritmi" }, { id: 2, skill: "kompjuterska-vizualizacija" }]
    },
    {
        id: 7,
        key: "business-analyst",
        resultGif: "business-analyst.gif",
        heading: "Бизнис аналитичар",
        text: "Во иднината, најголемите бизниси ќе бидат банки за овозможување на брзи кредити од криптовалути за вонземјани. Ти ќе бидеш тој што ќе го предвидува регресот и висината на орочување на вонземјаните во овие крипто-банки. Твоите предвидувања ќе бидат едни од најточните во универзумот! ",
        tags: ["#aliens4crypto"],
        skills: [{ id: 0, skill: "magionicar-za-excel" }, { id: 1, skill: "koristi-formuli" }, { id: 2, skill: "vizualizacija-na-podatoci" }]
    },
    {
        id: 8,
        key: "visual-animator",
        resultGif: "visual-animator.gif",
        heading: "Визуелен Аниматор",
        text: "Нема стопирање, само движење! Ќе измислиш нов метод на подвижни анимации и основниот прототип за креирање на првиот анимиран холограм од иднината. За твоите достигнувања ќе слуша цел универзум, а твоите анимации ќе бидат прикажувани од Times Square до првите светлечки реклами на Јупитер и Марс.",
        tags: ["#living4themotion", "#born2move"],
        skills: [{ id: 0, skill: "kreativno-razmisluvanje" }, { id: 1, skill: "interaktiven-dizajn" }, { id: 2, skill: "ljubopitnost" }]
    },
    {
        id: 9,
        key: "crypto-designer",
        resultGif: "crypto-designer.gif",
        heading: "Дизајнер на криптовалути",
        text: "Ти ќе бидеш Ворхол на криптовалутите на иднината! Во иднината физичките банкноти нема веќе да постојат, а токму твоето дело ќе биде највредната криптовалута. Твојот нов правец на дизајн ќе биде толку урбан и иновативен што сите ќе сакаат да си го носат во своите виртуелни новчаници. Ќе имаш дигитални изложби во сите футуристични музеи низ целиот универзум. А, твоите дела? Ќе може да се купуваат само преку криптовалути.",
        tags: ["#crypto2dend"],
        skills: [{ id: 0, skill: "trendseter" }, { id: 1, skill: "adobe-master" }, { id: 2, skill: "ilustrativen-virtuoz" }]
    },
    {
        id: 10,
        key: "uxui-designer",
        resultGif: "uxui-designer.gif",
        heading: "UX/UI дизајнер",
        text: "UX/UI дизајнот ќе биде најкористената наука во иднината, а ти ќе си совршениот научник! Спој на наука и дизајн! На твоите продукти ќе се восхитуваат сите корисници на модерните алатки. Ѕвездите велат дека ти ќе ја имаш способноста да ги предвидиш сите интереси на луѓето и вонземјаните.",
        tags: ["#design4science"],
        skills: [{ id: 0, skill: "psihologija" }, { id: 1, skill: "resava-problemi" }, { id: 2, skill: "empatija" }]
    },

]

export default CardsData