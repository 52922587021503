export const InCircle = () => (
  <svg
    width="239"
    height="239"
    viewBox="0 0 239 239"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="rotateLeft circle"
  >
    <path d="M237.755 119.724C237.755 148.912 226.983 177.073 207.504 198.809C188.024 220.546 161.208 234.328 132.195 237.516C103.182 240.703 74.0131 233.071 50.2796 216.081C26.546 199.092 9.91738 173.941 3.58097 145.45C-2.75543 116.958 1.64619 87.1305 15.9421 61.6836C30.2381 36.2368 53.4226 16.9611 81.0518 7.55139C108.681 -1.85837 138.811 -0.740242 165.667 10.6915C192.523 22.1231 214.215 43.0641 226.585 69.5006" />
  </svg>
);
export const OutCircle = () => (
  <svg
    width="286"
    height="286"
    viewBox="0 0 286 286"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="rotateRight circle"
  >
    <path d="M120.814 3.12102C155.357 -2.43276 190.734 4.95718 220.165 23.8744C249.596 42.7916 271.01 71.9052 280.303 105.635C289.595 139.364 286.113 175.337 270.522 206.658C254.932 237.978 228.331 262.443 195.818 275.364C163.305 288.284 127.167 288.75 94.331 276.674C61.4952 264.597 34.2716 240.826 17.8782 209.918C1.4848 179.011 -2.92504 143.14 5.49394 109.182C13.9129 75.2234 34.5684 45.5669 63.5013 25.8964" />
  </svg>
);
export const FB = () => (
  // <svg width="14" height="25" viewBox="0 0 14 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="shareIcon">
  //     <path d="M12.5122 14.0625L13.2065 9.53809H8.86523V6.60205C8.86523 5.36426 9.47168 4.15771 11.416 4.15771H13.3896V0.305664C13.3896 0.305664 11.5986 0 9.88623 0C6.31104 0 3.97412 2.16699 3.97412 6.08984V9.53809H0V14.0625H3.97412V25H8.86523V14.0625H12.5122Z" />
  // </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M16 0C7.164 0 0 7.164 0 16C0 24.836 7.164 32 16 32C24.836 32 32 24.836 32 16C32 7.164 24.836 0 16 0ZM20 10.6667H18.2C17.4827 10.6667 17.3333 10.9613 17.3333 11.704V13.3333H20L19.7213 16H17.3333V25.3333H13.3333V16H10.6667V13.3333H13.3333V10.256C13.3333 7.89733 14.5747 6.66667 17.372 6.66667H20V10.6667Z"
      fill="#8B8B8B"
    />
  </svg>
);
export const Linkedin = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M16 0C7.164 0 0 7.164 0 16C0 24.836 7.164 32 16 32C24.836 32 32 24.836 32 16C32 7.164 24.836 0 16 0ZM13.3333 21.3333H10.6667V13.3333H13.3333V21.3333ZM12 12.1453C11.1907 12.1453 10.5333 11.484 10.5333 10.6667C10.5333 9.85067 11.1893 9.188 12 9.188C12.8107 9.188 13.4667 9.85067 13.4667 10.6667C13.4667 11.484 12.8093 12.1453 12 12.1453ZM22.6667 21.3333H20.0027V17.5187C20.0027 15.0107 17.3333 15.2227 17.3333 17.5187V21.3333H14.6667V13.3333H17.3333V14.7907C18.496 12.636 22.6667 12.476 22.6667 16.8547V21.3333Z"
      fill="#8B8B8B"
    />
  </svg>
);
export const Twitter = () => (
  <svg
    width="29"
    height="23"
    viewBox="0 0 29 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="shareIcon"
  >
    <path d="M26.0191 5.73201C26.0375 5.98356 26.0375 6.23517 26.0375 6.48672C26.0375 14.1593 20.0572 23 9.12694 23C5.75952 23 2.63136 22.0476 0 20.3946C0.478445 20.4484 0.938426 20.4664 1.43528 20.4664C4.2138 20.4664 6.77158 19.55 8.81411 17.9867C6.20115 17.9328 4.01142 16.2617 3.25696 13.9617C3.62501 14.0156 3.99301 14.0515 4.37947 14.0515C4.91308 14.0515 5.44675 13.9796 5.94355 13.8539C3.2202 13.3148 1.17762 10.9789 1.17762 8.1578V8.08595C1.96884 8.51721 2.88897 8.78673 3.86415 8.82263C2.26326 7.78042 1.21444 6.00154 1.21444 3.98902C1.21444 2.91092 1.5088 1.92264 2.02406 1.06013C4.94984 4.582 9.34772 6.88196 14.2792 7.13357C14.1872 6.70232 14.1319 6.25314 14.1319 5.80391C14.1319 2.60544 16.7817 0 20.0755 0C21.7868 0 23.3325 0.70078 24.4182 1.83281C25.7614 1.58126 27.0495 1.09608 28.1904 0.431253C27.7487 1.77894 26.8103 2.91097 25.5774 3.62967C26.7735 3.50395 27.9328 3.18044 29 2.73127C28.1905 3.88122 27.1784 4.9054 26.0191 5.73201Z" />
  </svg>
);

export const Instagram = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M19.772 8.40267C18.788 8.35733 18.492 8.34933 16 8.34933C13.508 8.34933 13.2133 8.35867 12.2293 8.40267C9.69733 8.51867 8.51867 9.71733 8.40267 12.2293C8.35867 13.2133 8.348 13.508 8.348 16C8.348 18.492 8.35867 18.7867 8.40267 19.772C8.51867 22.2773 9.692 23.4827 12.2293 23.5987C13.212 23.6427 13.508 23.6533 16 23.6533C18.4933 23.6533 18.788 23.644 19.772 23.5987C22.304 23.484 23.4813 22.2813 23.5987 19.772C23.6427 18.788 23.652 18.492 23.652 16C23.652 13.508 23.6427 13.2133 23.5987 12.2293C23.4813 9.71867 22.3013 8.51867 19.772 8.40267ZM16 20.7933C13.3533 20.7933 11.2067 18.648 11.2067 16C11.2067 13.3533 13.3533 11.208 16 11.208C18.6467 11.208 20.7933 13.3533 20.7933 16C20.7933 18.6467 18.6467 20.7933 16 20.7933ZM20.9827 12.1387C20.364 12.1387 19.8627 11.6373 19.8627 11.0187C19.8627 10.4 20.364 9.89867 20.9827 9.89867C21.6013 9.89867 22.1027 10.4 22.1027 11.0187C22.1027 11.636 21.6013 12.1387 20.9827 12.1387ZM19.1107 16C19.1107 17.7187 17.7173 19.1107 16 19.1107C14.2827 19.1107 12.8893 17.7187 12.8893 16C12.8893 14.2813 14.2827 12.8893 16 12.8893C17.7173 12.8893 19.1107 14.2813 19.1107 16ZM16 0C7.164 0 0 7.164 0 16C0 24.836 7.164 32 16 32C24.836 32 32 24.836 32 16C32 7.164 24.836 0 16 0ZM25.2773 19.848C25.124 23.2413 23.2347 25.1213 19.8493 25.2773C18.8533 25.3227 18.5347 25.3333 16 25.3333C13.4653 25.3333 13.148 25.3227 12.152 25.2773C8.76 25.1213 6.87867 23.2387 6.72267 19.848C6.67733 18.8533 6.66667 18.5347 6.66667 16C6.66667 13.4653 6.67733 13.148 6.72267 12.152C6.87867 8.76 8.76133 6.87867 12.152 6.724C13.148 6.67733 13.4653 6.66667 16 6.66667C18.5347 6.66667 18.8533 6.67733 19.8493 6.724C23.2427 6.88 25.1253 8.76667 25.2773 12.152C25.3227 13.148 25.3333 13.4653 25.3333 16C25.3333 18.5347 25.3227 18.8533 25.2773 19.848Z"
      fill="#8B8B8B"
    />
  </svg>
);

export const Reset = () => (
  <svg
    width="33"
    height="23"
    viewBox="0 0 33 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="reset"
  >
    <path d="M13.2 9.85702H8.41665L8.4183 9.84224C8.63375 8.79182 9.05541 7.79411 9.6591 6.90636C10.5574 5.58845 11.8175 4.55562 13.2891 3.93105C13.7874 3.72076 14.3071 3.55976 14.8368 3.45297C15.9355 3.23117 17.0678 3.23117 18.1665 3.45297C19.7455 3.77513 21.1944 4.55275 22.3328 5.68897L24.6691 3.36918C23.6161 2.32041 22.3703 1.48319 20.9995 0.903175C20.3005 0.608486 19.5736 0.38394 18.8298 0.232869C17.295 -0.0776229 15.7132 -0.0776229 14.1785 0.232869C13.4341 0.384566 12.7067 0.609658 12.0071 0.904818C9.94542 1.77573 8.18059 3.22147 6.92505 5.06795C6.08006 6.31303 5.48916 7.71137 5.18595 9.18343C5.13975 9.40522 5.115 9.63195 5.082 9.85702H0L6.6 16.4287L13.2 9.85702ZM19.8 13.1428H24.5833L24.5817 13.156C24.1506 15.2618 22.904 17.1139 21.1117 18.3114C20.2204 18.913 19.2183 19.3329 18.1632 19.5469C17.065 19.7687 15.9333 19.7687 14.8351 19.5469C13.7802 19.3324 12.7782 18.9125 11.8866 18.3114C11.4487 18.0165 11.0399 17.681 10.6656 17.3093L8.3325 19.6323C9.38604 20.6808 10.6325 21.5175 12.0037 22.0967C12.7033 22.3924 13.4343 22.6175 14.1735 22.767C15.7077 23.0777 17.289 23.0777 18.8232 22.767C21.7809 22.1558 24.3825 20.42 26.0749 17.9286C26.9191 16.6845 27.5094 15.2873 27.8124 13.8164C27.857 13.5946 27.8834 13.3679 27.9164 13.1428H33L26.4 6.57121L19.8 13.1428Z" />
  </svg>
);
export const ShareIcon = ({ handlePopUp }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={handlePopUp}
    className="shareIconMobile"
  >
    <path d="M24 21.4399C22.9867 21.4399 22.08 21.8399 21.3867 22.4666L11.88 16.9332C11.9467 16.6266 12 16.3199 12 15.9999C12 15.6799 11.9467 15.3732 11.88 15.0666L21.28 9.58656C22 10.2532 22.9467 10.6666 24 10.6666C26.2133 10.6666 28 8.8799 28 6.66656C28 4.45323 26.2133 2.66656 24 2.66656C21.7867 2.66656 20 4.45323 20 6.66656C20 6.98656 20.0533 7.29323 20.12 7.5999L10.72 13.0799C10 12.4132 9.05333 11.9999 8 11.9999C5.78667 11.9999 4 13.7866 4 15.9999C4 18.2132 5.78667 19.9999 8 19.9999C9.05333 19.9999 10 19.5866 10.72 18.9199L20.2133 24.4666C20.1467 24.7466 20.1067 25.0399 20.1067 25.3332C20.1067 27.4799 21.8533 29.2266 24 29.2266C26.1467 29.2266 27.8933 27.4799 27.8933 25.3332C27.8933 23.1866 26.1467 21.4399 24 21.4399Z" />
  </svg>
);

export const SeeMore = ({ handlePopUp }) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="seeMore"
    onClick={handlePopUp}
  >
    <circle cx="18.0001" cy="18.0002" r="16.5" />
    <path
      d="M9 14.9999L11.115 12.8849L18 19.7549L24.885 12.8849L27 14.9999L18 23.9999L9 14.9999Z"
      fill="white"
    />
  </svg>
);
